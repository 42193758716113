

@media (min-width: 768px) {
  .card, .card-header, .card-body, .card-footer {
    border-radius: 0px !important;
  }
  .pdf-modal {
    --width: 80%;
    --height: 80%;
  }
}


.stream {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.exhibitor-description-text {
  font-size: 0.9rem;

  a {
    color: blue !important;
  }
}

.file-item {

  .file-description {
    line-height: 1rem;
  }

  .download-btn {
    text-align: right;
    font-size: 1.2rem;
    width: 100%;
    margin: auto;
  }
}

.exhibitor-logo {
  display: block;
  max-width: 90%;
  text-align: center;

  img {
    max-height: 90px;
  }
}

.exhibitor-detail {
  .action-buttons {
    .actn-btn {
      margin: 0;

      .actn-icon {
        font-size: 1.3rem !important;
      }

      .actn-text {
        font-size: 0.7rem !important;
        line-height: 0.8rem !important;
      }
    }

  }

  .booth-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .exhibitor-btn {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1rem;
      background: #3d2e9c;
      color: #fff;
      border-radius: 50px;
      height: 50px;
      width: 200px;
      text-align: center;
      margin: 20px 0px 0px 20px;


    }

    .request-btn {
      margin-top: 0px !important;
      width: 420px;
      display: inline-block;
    }
  }

}


@media(max-width: 576px) {
  .card, .card-header, .card-body, .card-footer {
    border-radius: 0px !important;
  }
  .file-item {
    max-width: 220px
  }
  .exhibitor-logo {
    display: block;
    max-width: 90%;
    text-align: center;

    img {
      max-height: 90px;
    }
  }
}


